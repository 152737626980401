import React from "react"
import Heart from "../../images/corazon.png"
import LineButton from "../../images/line_button.png"
import { Link } from "gatsby"

import buttonStyles from "./headerButton.module.css"

export default (props, location) => {
  return (
    <Link to={props.location ? "/" : props.page} state={{ fromFeed: true }}>
      <div className={buttonStyles.But}>
        <img
          className={buttonStyles.Line}
          src={LineButton}
          id={props.location ? buttonStyles.showLine : ""}
        ></img>
        <h2 className={buttonStyles.TextBut}>{props.text}</h2>
        <img
          className={buttonStyles.Cora}
          src={Heart}
          id={props.location ? buttonStyles.showLine : ""}
        ></img>
      </div>
    </Link>
  )
}
