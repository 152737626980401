import React, { Component } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { withTranslation } from "react-i18next"

import contactStyles from "./contact.module.css"

const cl1 = {
  fill: "#8D95FF",
}

const cl2 = {
  transform: "scale(-1,1)",
}

const text = {
  fill: "white",
  fontSize: "31px",
  fontFamily: "Lato",
  fontWeight: "500",
  userSelect: "none",
}

const mail = {
  fill: "white",
  fontSize: "38px",
  fontFamily: "Lato",
  fontWeight: "700",
}

const final = {
  fill: "white",
  fontSize: "40px",
  fontFamily: "Lato",
  fontWeight: "500",
  userSelect: "none",
}

class presImg extends Component {
  constructor(props) {
    super(props)
    this.state = {
      t: props.t,
      i18n: props.i18n,
    }

    this.texta = {
      en: {
        space: 65,
        xPos: 950,
        yPos: 750,
        off2: 9,
        off3: 0,
        off4: 200,
        off5: -60,
        off6: 330,
      },
      es: {
        space: 65,
        xPos: 873,
        yPos: 780,
        off2: 9,
        off3: 40,
        off4: 250,
        off5: -60,
        off6: 330,
      },
      fr: {
        space: 65,
        xPos: 920,
        yPos: 750,
        off2: 9,
        off3: 0,
        off4: 200,
        off5: -60,
        off6: 330,
      },
    }
  }

  render() {
    var lan = this.state.i18n.languages[0]
    console.log(lan)
    return (
      <div className={contactStyles.ImgManch}>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="840 350 980 1300"
        >
          <g id="Capa_13">
            <g>
              <path
                class="st3"
                style={cl1}
                d="M981.24,650.02c-276.53,174.56-73.58,545.38,149.1,381.21c56.53-31.52,104.49-34.71,163.29-30.75
			c125.99,11.79,135.15,92.2,197.35,120.87c136.06,61.66,259.48,5.32,296.88-113.94c97.2-367.04-337.92-428-342.68-490.75
			c-107.03-166.07-291.22-149.68-345.92-72.24C1052.72,517.75,1114.08,551.89,981.24,650.02z"
              />
              <path
                class="st3"
                style={cl1}
                d="M1596.71,476.97c-4.8,46.94,43.15,20.59,61.88,65.74C1744.73,592.63,1637.39,399.55,1596.71,476.97z"
              />
              <path
                class="st3"
                style={cl1}
                d="M1245.48,1033.08c-50.06-5.35,6.95,42.95,20.49,32.11c10.22-10.18-21.3-16.14-13.09-26.52
			C1258.96,1033.67,1256.24,1023.77,1245.48,1033.08z"
              />
              <path
                class="st3"
                style={cl1}
                d="M1687.33,602.06c-1.79-20.6-27.91-18.45-28.52,0C1660.4,623,1686.45,620.22,1687.33,602.06z"
              />
              <path
                class="st3"
                style={cl1}
                d="M963.52,1055.83c-1.41-16.7-22.74-15.16-23.35,0C941.3,1072.62,962.68,1070.89,963.52,1055.83z"
              />
            </g>{" "}
          </g>

          <text>
            <tspan
              x={this.texta[lan].xPos}
              y={this.texta[lan].yPos}
              style={text}
            >
              {this.state.t("info.1")}
            </tspan>
            <tspan
              x={this.texta[lan].xPos + this.texta[lan].off2}
              y={this.texta[lan].yPos + 1 * this.texta[lan].space}
              style={text}
            >
              {this.state.t("info.2")}
            </tspan>
            <tspan
              x={this.texta[lan].xPos + this.texta[lan].off3}
              y={this.texta[lan].yPos + 2 * this.texta[lan].space}
              style={text}
            >
              {this.state.t("info.3")}
            </tspan>
            <tspan
              x={this.texta[lan].xPos + this.texta[lan].off4}
              y={this.texta[lan].yPos + 3 * this.texta[lan].space}
              style={mail}
            >
              {this.state.t("info.4")}
            </tspan>
          </text>
        </svg>
      </div>
    )
  }
}

export default withTranslation()(presImg)
