import React, { Component } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { withTranslation } from "react-i18next"

import contactStyles from "./contact.module.css"

const cl1 = {
  fill: "#FF6161",
}

const text = {
  fill: "white",
  fontSize: "40px",
  fontFamily: "Lato",
  fontWeight: "500",
  userSelect: "none",
}

const mail = {
  fill: "white",
  fontSize: "40px",
  fontFamily: "Lato",
  fontWeight: "700",
}

const final = {
  fill: "white",
  fontSize: "58px",
  fontFamily: "Lato",
  fontWeight: "500",
  userSelect: "none",
}

class presImg extends Component {
  constructor(props) {
    super(props)
    this.state = {
      t: props.t,
      i18n: props.i18n,
    }

    this.texta = {
      en: {
        space: 100,
        xPos: 300,
        yPos: 600,
        off2: 9,
        off3: 0,
        off4: 330,
        off5: 0,
        off6: 40,
      },
      es: {
        space: 100,
        xPos: 300,
        yPos: 600,
        off2: 9,
        off3: 0,
        off4: 330,
        off5: 0,
        off6: 50,
      },
      fr: {
        space: 100,
        xPos: 320,
        yPos: 540,
        off2: 9,
        off3: 0,
        off4: 330,
        off5: -80,
        off6: 0,
      },
    }
  }

  render() {
    var lan = this.state.i18n.languages[0]
    return (
      <div className={contactStyles.ImgManch}>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1888.47 1326.05"
        >
          <g id="Capa_13" style={cl1}>
            <g>
              <g>
                <path
                  class="st3"
                  d="M689.8,1189.91c362.51-253.59,369.66-30.15,705.86,51.9c339.07,74.64,632.9-363.04,204.84-532.74
				c-209.08-136.54,83.23-182.37,132.15-303.44c97.53-175.38-112.68-258.92-246.03-256.15c-96.65,1.37-185.26,35.47-256.74,100.48
				c-310.12,341.97-575.4-382.39-1049.56-39.69C31.65,348.07,202.2,489.75,194.31,626.62c-1.34,41.68-18.63,72.32-50.6,103.67
				c-150.29,120.52-30.77,421.97,127.87,346.79c90.4-39.62,142.05-192.45,232-150.9c63.93,43.61-33.69,89.85-30.7,140.88
				C454.77,1225.65,608,1249.74,689.8,1189.91z"
                />
              </g>
              <path
                class="st3"
                d="M318.85,1110.88c-0.22,18.95,32.7,26.53,36.24,0C355.3,1091.93,322.39,1084.35,318.85,1110.88z"
              />
              <path
                class="st3"
                d="M343.33,1030.08c0.53,19.02,36.87,11.27,17.69-10.15C352.88,1015.85,346.75,1018.6,343.33,1030.08z"
              />
              <path
                class="st3"
                d="M231.18,1120.4c-0.47,16.56,33.61,3.66,9.06-9.06C237.47,1111.79,234.74,1111.97,231.18,1120.4z"
              />
              <path
                class="st3"
                d="M1051.97,1130.39c-17.67-47.06-145.33-33.18-109.98,8.83c16.75,6.08,46.14-14.15,54.89-5.1
			C1023.62,1186.33,1072.99,1169.27,1051.97,1130.39z"
              />
            </g>
          </g>

          <text>
            <tspan
              x={this.texta[lan].xPos + this.texta[lan].off5}
              y={this.texta[lan].yPos}
              style={final}
            >
              {this.state.t("infoPhone.5")}
            </tspan>
            <tspan
              x={this.texta[lan].xPos + this.texta[lan].off6}
              y={this.texta[lan].yPos + this.texta[lan].space}
              style={final}
            >
              {this.state.t("infoPhone.6")}
            </tspan>
          </text>
        </svg>
      </div>
    )
  }
}

export default withTranslation()(presImg)
