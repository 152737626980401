import React, { Component } from "react"
import contactStyles from "./contact.module.css"

import Insta from "./infosvgb"
import Info from "./infosvga"

import { Link, navigate } from "gatsby"

class contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: false,
      closeMe: props.closing,
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.state.closeMe()
  }

  render() {
    return (
      <div className={contactStyles.Global} onClick={this.handleClick}>
        <div className={contactStyles.Top}>
          <Info></Info>
        </div>
        <div className={contactStyles.Bottom}>
          <Insta></Insta>
        </div>
        <div className={contactStyles.Dino}>
          <img
            className={contactStyles.LittleImg}
            src="/static/083eb6562597786c9ff6789d4584ecb1/ee604/dino.png"
          ></img>
        </div>
        <div className={contactStyles.Star1}>
          <img
            className={contactStyles.LittleImg}
            src="/static/65fcd1619671ee84638a5a8ab53c5c45/ee604/star.png"
          ></img>
        </div>
        <div className={contactStyles.Star2}>
          <img
            className={contactStyles.LittleImg}
            src="/static/65fcd1619671ee84638a5a8ab53c5c45/ee604/star.png"
          ></img>
        </div>
        <div className={contactStyles.Star3}>
          <img
            className={contactStyles.LittleImg}
            src="/static/65fcd1619671ee84638a5a8ab53c5c45/ee604/star.png"
          ></img>
        </div>

        <div className={contactStyles.Close} onClick={this.handleClick}>
          <img
            className={contactStyles.LittleImg}
            src="/static/8cba15cbf49bb7fe0db2d2922d2ff9f4/ee604/closePhone.png"
          ></img>
        </div>
        <a href="https://www.instagram.com/lizdelmarg/" target="_blank">
          <div className={contactStyles.Insta}>
            <img
              className={contactStyles.LittleImg}
              src="/static/48e8c09d8081cf46ff38dfc6cc425d95/ee604/instagram_Liz_del_Mar.png"
            ></img>
          </div>
        </a>
      </div>
    )
  }
}

export default contact
