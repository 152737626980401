import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { withTrans } from "../i18n/withTrans"

import Header from "./header/header"
import "./layout.css"
import LayoutStyle from "./layout.module.css"

import Transitions from "./transitions"

import Footer from "./footer"

const styleLeft = { display: "none" }
const styleRight = { width: "100%" }

const Layout = ({ location, children, t, i18n }) => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "perrito" }) {
        id
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `)
  // console.log("desde layoooout")
  // if (window.sessionStorage.getItem("scroll") === "true") {
  //   console.log("aca hariamos un scroll")
  //   console.log(parseInt(window.sessionStorage.getItem("scrollPos")))

  //   window.sessionStorage.setItem("scroll", "false")
  //   window.scrollTo({
  //     top: 5000,
  //     left: 0,
  //     behavior: "smooth",
  //   })
  // }

  // window.scrollTo({
  //   top: 5000,
  //   left: 0,
  //   behavior: "smooth",
  // })
  // console.log("recargandooooooo")

  var about = false
  if (location.pathname) {
    about = location.pathname.includes("about")
  }

  return (
    <>
      <div
        style={{
          margin: `0 auto 0 auto`,
          maxWidth: `1920px`,
          padding: `0 0rem 0rem 0rem`,
          width: `100vw`,
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        <Header location={location.pathname} />
        <Transitions location={location}>
          <div className={LayoutStyle.TheBody}>
            <div
              className={LayoutStyle.InsideBodyLeft}
              style={about ? styleLeft : {}}
            ></div>
            <div
              className={LayoutStyle.InsideBodyRight}
              style={about ? styleRight : {}}
            >
              {children}

              {about ? (
                <div></div>
              ) : (
                <img
                  src={data.file.childImageSharp.fluid.src}
                  id={LayoutStyle.Perrito}
                ></img>
              )}
              <img
                className={LayoutStyle.honguito}
                src="/static/f24d1581103294e2ec5cba1ab11a43a7/ee604/hong.png"
              ></img>
            </div>
          </div>
        </Transitions>
        {about ? <div></div> : <Footer></Footer>}
      </div>
    </>
  )
}

Layout.defaultProps = {
  location: {},
}

export default withTrans(Layout)
