import React, { Component } from "react"
import LanguageMenu from "../language"
import headerStyles from "./headerPhone.module.css"
import { Link, navigate } from "gatsby"

import TheMenu from "../sideMenu/theMenu"

import Title from "../../images/title.png"
import Language from "../sideMenu/language"

import Contact from "./contactPhone/contactPhone"

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = props.handleClick
    this.state = { showMenu: props.show, first: true }
    this.targetElement = null
    this.manageContact = props.contactFun
  }
  componentWillReceiveProps(newProps) {
    this.setState(() => ({
      showMenu: newProps.show,
    }))
  }

  componentDidMount() {
    this.targetElement = document.querySelector("workwork")
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  render() {
    {
      if (this.state.first) {
        this.state.first = false
        return <div></div>
      } else {
        if (this.state.showMenu) {
          disableBodyScroll(this.targetElement)
        } else {
          enableBodyScroll(this.targetElement)
        }

        return (
          <div>
            <div
              className={headerStyles.Menu}
              id={this.state.showMenu ? headerStyles.Down : headerStyles.Up}
            >
              <div className={headerStyles.TheMenu} onClick={this.handleClick}>
                <TheMenu manageCon={this.manageContact}></TheMenu>
              </div>
              <div
                className={headerStyles.TheMenu}
                id={headerStyles.TheMenuImg}
              >
                <img
                  className={headerStyles.TheImg}
                  src="/static/2a6b825bc706e69340708624242a2476/ee604/rImg.png"
                ></img>
              </div>
            </div>
            <div
              className={headerStyles.footerMenu}
              id={this.state.showMenu ? headerStyles.in : headerStyles.out}
            >
              <Language></Language>
            </div>
          </div>
        )
      }
    }
  }
}

class AboutModal extends Component {
  constructor(props) {
    super(props)
    this.state = { showMenu: false, first: true, showContact: false }
    this.handleClick = this.handleClick.bind(this)
    this.manageContact = this.manageContact.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.hashChange = this.hashChange.bind(this)
  }

  handleClick() {
    this.setState(prevState => ({
      showMenu: !prevState.showMenu,
    }))
  }

  closeMenu() {
    this.setState(prevState => ({
      showMenu: false,
    }))
  }

  componentDidMount() {
    window.onpopstate = this.hashChange
    if (this.state.first) {
      window.history.pushState({}, "page 2", this.props.location)
      this.state.first = false
    }
  }

  componentWillUnmount() {
    window.onpopstate = () => {}
  }

  hashChange() {
    if (this.state.showMenu) {
      this.closeMenu()
      navigate(this.props.location, { replace: false })
    }
    if (this.state.showContact) {
      this.manageContact(false)
      navigate(this.props.location, { replace: false })
    }
  }

  manageContact(value) {
    this.setState(prevState => ({
      showContact: value,
    }))
  }

  render() {
    return (
      <div>
        <header className={headerStyles.HeadAbso}>
          <Link to="/">
            <div className={headerStyles.imgContainer} onClick={this.closeMenu}>
              <img className={headerStyles.HeadImg} src={Title} />
            </div>
          </Link>
          <div id="contLabel" className={headerStyles.MenuButtonContainer}>
            <img
              src="/static/622d51e33d7ef07015cfbce6aede9ad4/63a9c/menuPhone.png"
              className={headerStyles.MenuButton}
              onClick={this.handleClick}
            ></img>
          </div>
        </header>
        <Menu
          show={this.state.showMenu}
          handleClick={this.closeMenu}
          contactFun={this.manageContact}
        ></Menu>
        {this.state.showContact ? (
          <Contact
            closing={() => {
              this.manageContact(false)
            }}
            locations={this.props.location}
          ></Contact>
        ) : (
          ""
        )}
      </div>
    )
  }
}

export default AboutModal
