import React, { Component } from "react"
import contactStyles from "./contact.module.css"

import Info from "./infosvg"

class contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: false,
      closeMe: props.closing,
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.state.closeMe()
    // this.setState(prevState => ({
    //   selected: !prevState.selected,
    // }))
  }

  render() {
    return (
      <div className={contactStyles.Global}>
        <div className={contactStyles.Manchita}>
          <Info></Info>
        </div>
        <div className={contactStyles.Gato}>
          <img
            className={contactStyles.LittleImg}
            src="/static/1e2c3c88ece997953ad02c722c6bbc68/ee604/gatomago.png"
          ></img>
        </div>
        <div className={contactStyles.Cuq}>
          <img
            className={contactStyles.LittleImg}
            src="/static/8f9e295bb2ad1df790a25eda473d602b/ee604/cuquitos.png"
          ></img>
        </div>
        <div className={contactStyles.Sol}>
          <img
            className={contactStyles.LittleImg}
            src="/static/e8fc8caeda1bab232fa02886fd9ccb0e/ee604/donsol.png"
          ></img>
        </div>
        <div className={contactStyles.Rue}>
          <img
            className={contactStyles.LittleImg}
            src="/static/5d10a96d45aeb948b8272040e7c7ff9e/ee604/ruedita.png"
          ></img>
        </div>
        <div className={contactStyles.Close} onClick={this.handleClick}>
          <img
            className={contactStyles.LittleImg}
            src="/static/44075f2983fb48e382ff817021af293d/ee604/close.png"
          ></img>
        </div>
        <a href="https://www.instagram.com/lizdelmarg/" target="_blank">
          <div className={contactStyles.Insta}>
            <img
              className={contactStyles.LittleImg}
              src="/static/48e8c09d8081cf46ff38dfc6cc425d95/ee604/instagram_Liz_del_Mar.png"
            ></img>
          </div>
        </a>
      </div>
    )
  }
}

export default contact
