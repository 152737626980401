// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-editorial-js": () => import("./../src/pages/editorial.js" /* webpackChunkName: "component---src-pages-editorial-js" */),
  "component---src-pages-illustration-js": () => import("./../src/pages/illustration.js" /* webpackChunkName: "component---src-pages-illustration-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-more-js": () => import("./../src/pages/more.js" /* webpackChunkName: "component---src-pages-more-js" */)
}

