import React from "react"

// import containerStyles from "./commingSoon.module.css"

export default props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.219"
      height="29.378"
      viewBox="0 0 33.219 29.378"
    >
      <g
        id="Grupo_4"
        data-name="Grupo 4"
        transform="translate(-201.073 -90.989)"
      >
        <g
          id="Grupo_3"
          data-name="Grupo 3"
          transform="translate(-422.927 -24.011)"
        >
          <g id="Grupo_2" data-name="Grupo 2" transform="translate(624 115)">
            <path
              id="Trazado_1"
              data-name="Trazado 1"
              d="M640.594,122.643a6.973,6.973,0,0,0-.292-1.035c-1.675-4.529-8.378-7.919-12.55-6.113-4.711,2.038-4.885,10.514-1.09,15.7.118.16.223.3.328.423a17.315,17.315,0,0,0,1.436,1.542c1.5,1.407,2.257,2.109,2.891,2.631,1.174.963,3.643,3.167,9.311,8.589m-.034-21.736a7.2,7.2,0,0,1,.323-1.035c1.792-4.53,8.416-7.9,12.55-6.113,4.711,2.04,4.885,10.514,1.089,15.7-.117.16-.222.3-.327.423a16.922,16.922,0,0,1-1.436,1.542c-1.5,1.407-2.256,2.109-2.891,2.631-1.173.963-3.634,3.164-9.274,8.589"
              transform="translate(-624 -115)"
              fill={props.checked ? "#ff6161" : "#8d95ff"}
            />
          </g>
        </g>
        <text
          id="Es"
          transform="translate(210 110)"
          fill={props.checked ? "#fcb4b4" : "#dee1ff"}
          fontSize="14"
          fontFamily="Roboto-Bold, Roboto"
          fontWeight="700"
        >
          <tspan x="0" y="0">
            {props.text}
          </tspan>
        </text>
      </g>
    </svg>
  )
}
