import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import LanguageMenu from "./locale/language"
import headerStyles from "./header.module.css"
import Title from "../../images/title.png"
import HeaderButton from "./headerButton"
import ContactButton from "./contactButton"
import HeaderPhone from "./headerPhone"

import { useTranslation } from "react-i18next"

const Header = ({ siteTitle, location }) => {
  const { t, i18n } = useTranslation()

  return (
    <header className={headerStyles.HeadAbs}>
      <div className={headerStyles.headerPhone}>
        <HeaderPhone location={location} />
      </div>

      <div className={headerStyles.headerBig}>
        <Link to="/" className={headerStyles.ImgContainer}>
          <img className={headerStyles.HeadImg} src={Title} />
        </Link>
        <div className={headerStyles.ButtonsOut}>
          <div className={headerStyles.ButtonsIn}>
            <HeaderButton
              text={t("header.illustration")}
              page="illustration"
              location={location.includes("illustration")}
            ></HeaderButton>
            <HeaderButton
              text={t("header.editorial")}
              page="editorial"
              location={location.includes("editorial")}
            ></HeaderButton>
            <HeaderButton
              text={t("header.more")}
              page="more"
              location={location.includes("more")}
            ></HeaderButton>

            <HeaderButton
              text={t("header.about")}
              page="about"
              location={location.includes("about")}
            ></HeaderButton>
            <ContactButton text={t("header.contact")}></ContactButton>
          </div>
        </div>

        <div className={headerStyles.Lang}>
          <LanguageMenu />
        </div>
      </div>
    </header>
  )
}

Header.defaultProps = {
  location: " ",
}

export default Header
