import React from "react"
import { Link } from "gatsby"
import Items from "./items"
import ContactItem from "./contact_item"

import menuStyles from "./menuStyles.module.css"

import { useTranslation } from "react-i18next"

export default props => {
  const { t, i18n } = useTranslation()
  return (
    <div className={menuStyles.Menu}>
      <Items text={t("header.illustration")} page="illustration"></Items>
      <Items text={t("header.editorial")} page="editorial"></Items>
      <Items text={t("header.more")} page="more"></Items>
      <Items text={t("header.about")} page="about"></Items>
      <ContactItem
        text={t("header.contact")}
        page="contact"
        manCont={props.manageCon}
      ></ContactItem>
    </div>
  )
}
