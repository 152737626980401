import React, { Component } from "react"
import Heart from "../../images/corazon.png"
import LineButton from "../../images/line_button.png"
import { Link } from "gatsby"

import buttonStyles from "./headerButton.module.css"

import Contact from "./contact/contact.js"
import { composeInitialProps } from "react-i18next"

class contactB extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: false,
      text: props.text,
    }
    this.handleClick = this.handleClick.bind(this)
    this.closeContact = this.closeContact.bind(this)
  }

  componentWillReceiveProps(newProps) {
    this.setState(() => ({
      text: newProps.text,
    }))
  }

  handleClick() {
    this.setState(prevState => ({
      selected: !prevState.selected,
    }))
  }

  closeContact() {
    this.setState(prevState => ({
      selected: false,
    }))
  }

  render() {
    return (
      <div
        className={buttonStyles.But}
        onClick={this.state.selected ? () => {} : this.handleClick}
      >
        <img
          className={buttonStyles.Line}
          src={LineButton}
          id={this.state.selected ? buttonStyles.showLine : ""}
        ></img>
        <h2 className={buttonStyles.TextBut}>{this.state.text}</h2>
        <img
          className={buttonStyles.Cora}
          src={Heart}
          id={this.state.selected ? buttonStyles.showLine : ""}
        ></img>
        {this.state.selected ? (
          <Contact closing={this.closeContact}></Contact>
        ) : (
          ""
        )}
      </div>
    )
  }
}

export default contactB
