import React, { useState } from "react"
import { Select } from "@material-ui/core"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles({
  selectFocus: {
    "&:focus": {
      background: "transparent",
    },
  },
})

const LanguageMenu = props => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()

  const [values, setValues] = useState({
    language: "en",
  })

  function handleChange(event) {
    i18n.changeLanguage(event.target.value)

    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }))
  }

  return (
    <Select
      value={values.language}
      onChange={e => handleChange(e)}
      disableUnderline
      inputProps={{
        name: "language",
      }}
      classes={{
        select: classes.selectFocus,
      }}
    >
      <MenuItem value={"en"}>
        <h3>EN</h3>
      </MenuItem>
      <MenuItem value={"es"}>
        <h3>ES</h3>
      </MenuItem>
      <MenuItem value={"fr"}>
        <h3>FR</h3>
      </MenuItem>
    </Select>
  )
}

export default LanguageMenu
