import React from "react"

// import containerStyles from "./commingSoon.module.css"
import Coucou from "./coucou"
import Hi from "./hi"
import Hola from "./hola"
import shortid from "shortid"
import { withTranslation } from "react-i18next"

import Styles from "./globito.module.css"
import { StepButton } from "@material-ui/core"

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: props.status,
      classGlobal: [
        Styles.selectedGlobal,
        Styles.noselectedUpGlobal,
        Styles.noselectedDownGlobal,
      ],
      classGlobito: [
        Styles.selectedGlobito,
        Styles.noSelectedUpGlobito,
        Styles.noSelectedDownGlobito,
      ],
      langue: props.langue,
      colors: { fr: "#8D95FF", en: "#FF6161", es: "#FCB4B4" },
      colorsText: { fr: "#DEE1FF", en: "#FCB4B4", es: "#8D95FF" },
      stylesText: [
        Styles.TextLanSelected,
        Styles.TextLanNoSelectedUp,
        Styles.TextLanNoSelectedDown,
      ],
      handleClick: props.clFunction,
      text: {
        fr: "Fr",
        en: "En",
        es: "Es",
      },
      idAnimation: [
        [Styles.Nothing, Styles.uptocenter, Styles.bottomtocenter],
        [Styles.Nothing, Styles.centertoup, Styles.Nothing],
        [Styles.Nothing, Styles.Nothing, Styles.centertobottom],
      ],
      hover: false,
      prev: 5,
      changeLangue: false,
      keye: 234,
    }
    this.getRandomKey = this.getRandomKey.bind(this)
    this.onHover = this.onHover.bind(this)
    this.onLeave = this.onLeave.bind(this)
  }

  componentWillReceiveProps(newProps) {
    console.log("recibiendoooo")
    var keying
    if (this.state.langue === newProps.langue) {
      keying = this.state.keye
    } else {
      keying = this.getRandomKey()
    }
    this.setState(() => ({
      langue: newProps.langue,
      prev: newProps.prev,
      changeLangue: true,
      keye: keying,
    }))
  }

  getRandomKey = () => {
    return shortid.generate()
  }

  onHover = () => {
    this.setState(prevState => ({
      hover: true,
      changeLangue: false,
    }))
  }

  onLeave = () => {
    this.setState(prevState => ({
      hover: false,
      changeLangue: false,
    }))
  }

  render() {
    var estilos

    if (this.state.status !== 0 && this.state.hover) {
      estilos = {
        width: "42%",
        maxWidth: "65px",
      }
    } else {
      estilos = {}
    }

    return (
      <div
        style={estilos}
        className={this.state.classGlobal[this.state.status]}
        id={this.state.idAnimation[this.state.status][this.state.prev]}
        key={this.state.keye}
      >
        <div className={this.state.classGlobito[this.state.status]}>
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 480 467.92"
          >
            <path
              fill={this.state.colors[this.state.langue]}
              className={this.state.hover ? "" : Styles.Svgs}
              d="M449.16,216.16c-5.05,47.25-43.67,63.66-39.71,116.7c5.24,70.16,78.07,111.56,69.8,123.43
          c-4.15,5.96-27.62,2.82-125.14-48.84c-22.65,16.05-65.45,41.02-120.96,41.71C130.22,450.42,5.26,367.93,0.16,233.14
          C-4.53,109.16,92.18,4.84,216.17,0.15C359.77-5.28,458,133.43,449.16,216.16z"
            />
          </svg>
        </div>
        <div
          className={this.state.stylesText[this.state.status]}
          onMouseOver={this.onHover}
          onMouseLeave={this.onLeave}
          onClick={this.state.handleClick}
          style={{ color: this.state.colorsText[this.state.langue] }}
        >
          {this.state.text[this.state.langue]}
        </div>
      </div>
    )
  }
}

export default withTranslation()(Menu)
