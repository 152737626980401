import React from "react"
import { withTrans } from "../i18n/withTrans"
import { navigate } from "gatsby"

import "./layout.css"

import StylesFooter from "./footer.module.css"

const Layout = ({ t, i18n }) => {
  return (
    <div className={StylesFooter.Global}>
      <div className={StylesFooter.LeftFooter}>
        <div className={StylesFooter.textContainer}>
          {t("footer.0") + t("footer.1") + t("footer.2")}
        </div>
      </div>
      <div className={StylesFooter.RightFooter}>
        <div className={StylesFooter.iconsContainer}>
          <img
            className={StylesFooter.Icons}
            src="/static/74a660215a2ae4cfd32714d82ad80ad7/ee604/facebook_Liz_del_Mar.png"
          ></img>
          <img
            className={StylesFooter.Icons}
            src="/static/48e8c09d8081cf46ff38dfc6cc425d95/ee604/instagram_Liz_del_Mar.png"
          ></img>
        </div>
      </div>
    </div>
  )
}

export default withTrans(Layout)
