import React, { Component } from "react"
import { withTranslation } from "react-i18next"
import LanStyles from "./laguage.module.css"
import shortid from "shortid"
import Globito from "./globito"

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      t: props.t,
      i18n: props.i18n,
      language: "en",
      order: ["en", "es", "fr"],
      indx: 1,
      text: {
        en: "En",
        es: "Es",
        fr: "Fr",
      },
      first: true,
    }
    this.getRandomKey = this.getRandomKey.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  getRandomKey = () => {
    return shortid.generate()
  }

  handleClick(langue, idx) {
    if (idx !== 0) {
      this.state.i18n.changeLanguage(langue)
      if (idx === 1) {
        console.log(idx)

        this.setState(prevState => ({
          order: [prevState.order[1], prevState.order[0], prevState.order[2]],
          indx: idx,
          laguage: langue,
        }))
      } else {
        this.setState(prevState => ({
          order: [prevState.order[2], prevState.order[1], prevState.order[0]],
          indx: idx,
          laguage: langue,
        }))
      }
    }
  }

  render() {
    return (
      <div className={LanStyles.GlobalAbsolute}>
        <Globito
          status={0}
          clFunction={() => this.handleClick(this.state.order[0], 0)}
          langue={this.state.order[0]}
          prev={this.state.indx}
        ></Globito>
        <Globito
          status={1}
          clFunction={() => this.handleClick(this.state.order[1], 1)}
          langue={this.state.order[1]}
          prev={this.state.indx}
        ></Globito>
        <Globito
          status={2}
          clFunction={() => this.handleClick(this.state.order[2], 2)}
          langue={this.state.order[2]}
          prev={this.state.indx}
        ></Globito>
      </div>
    )
  }
}

export default withTranslation()(Menu)
