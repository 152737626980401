import React, { Component } from "react"
import Locale from "./oneLocale"
import { withTranslation } from "react-i18next"
import LanStyles from "./laguage.module.css"
import shortid from "shortid"

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      t: props.t,
      i18n: props.i18n,
      language: "en",
      order: ["en", "es", "fr"],
      indx: 1,
      text: {
        en: "En",
        es: "Es",
        fr: "Fr",
      },
      first: true,
    }
    this.handleClick = this.handleClick.bind(this)
    this.getRandomKey = this.getRandomKey.bind(this)
  }

  handleClick(langue, idx) {
    if (idx !== 1) {
      this.state.i18n.changeLanguage(langue)
      if (idx === 0) {
        console.log(idx)

        this.setState(prevState => ({
          order: [prevState.order[1], prevState.order[0], prevState.order[2]],
          indx: idx,
          laguage: langue,
        }))
      } else {
        this.setState(prevState => ({
          order: [prevState.order[0], prevState.order[2], prevState.order[1]],
          indx: idx,
          laguage: langue,
        }))
      }
    }
  }

  onAnimationStart = () => {
    console.log("inicio")
  }

  onAnimationEnd = () => {
    console.log("se acabo")
  }

  getRandomKey = () => {
    return shortid.generate()
  }

  render() {
    var lang = this.state.i18n.languages[0]
    if (this.state.order[1] !== lang) {
      var idx = this.state.order.indexOf(lang)
      this.state.order[idx] = this.state.order[1]
      this.state.order[1] = lang
    }
    console.log(this.state.indx)

    return (
      <div className={LanStyles.BigMenu}>
        <div
          className={LanStyles.leftC}
          key={this.getRandomKey()}
          id={this.state.indx === 0 ? LanStyles.cenlef : ""}
          onClick={() => this.handleClick(this.state.order[0], 0)}
        >
          <Locale
            text={this.state.text[this.state.order[0]]}
            checked={this.state.i18n.languages[0] == this.state.order[0]}
          ></Locale>
        </div>
        <div
          className={LanStyles.centered}
          key={this.getRandomKey()}
          id={
            this.state.indx === 1
              ? " "
              : this.state.indx === 0
              ? LanStyles.lefcen
              : LanStyles.rigcen
          }
        >
          <Locale
            text={this.state.text[this.state.order[1]]}
            checked={this.state.i18n.languages[0] == this.state.order[1]}
          ></Locale>
        </div>
        <div
          className={LanStyles.rightC}
          key={this.getRandomKey()}
          id={this.state.indx === 2 ? LanStyles.cenrig : ""}
          onClick={() => this.handleClick(this.state.order[2], 2)}
        >
          <Locale
            text={this.state.text[this.state.order[2]]}
            checked={this.state.i18n.languages[0] == this.state.order[2]}
          ></Locale>{" "}
        </div>
      </div>
    )
  }
}

export default withTranslation()(Menu)
