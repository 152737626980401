import React, { Component } from "react"
import menuStyles from "./menuStyles.module.css"

class contactB extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: props.text,
    }
    this.handleClick = this.handleClick.bind(this)
    this.manageContact = props.manCont
  }

  componentWillReceiveProps(newProps) {
    this.setState(() => ({
      text: newProps.text,
    }))
  }

  handleClick() {
    this.manageContact(true)
  }

  render() {
    return (
      <div
        className={menuStyles.LinkP}
        onClick={this.state.selected ? () => {} : this.handleClick}
      >
        <h2 className={menuStyles.Item}>{this.state.text}</h2>
      </div>
    )
  }
}

export default contactB
