import React from "react"
// import Heart from "../images/corazon.png"
// import LineButton from "../images/line_button.png"
import { Link } from "gatsby"

import menuStyles from "./menuStyles.module.css"

export default props => {
  return (
    <Link className={menuStyles.LinkP} to={props.page}>
      <div>
        <h2 className={menuStyles.Item}>{props.text}</h2>
      </div>
    </Link>
  )
}
